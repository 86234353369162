import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.js";
import { Grid, Button, Flex } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";
import Opiniones from "../components/Opiniones";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`👩‍🏫 Bienvenue`}</h1>
    <p><strong parentName="p">{`Clases particulares de francés online`}</strong>{`.
Puedes optar por un curso individual o grupal, con fines personales, turísticos, académicos o profesionales.`}</p>
    <h3>{`Metodología`}</h3>
    <p>{`Aprenderemos con el `}<strong parentName="p">{`método léxico-gramatical moderno`}</strong>{`, un sistema de aprendizaje que contempla 4 componentes principales: `}<em parentName="p">{`hablar, escuchar, leer y escribir`}</em>{`.
Los contenidos se introducen a través de ejercicios prácticos y actividades de comprensión y expresión.`}</p>
    <h3>{`Modalidad`}</h3>
    <ul>
      <li parentName="ul">{`El curso considera 2 clases semanales como mínimo.`}</li>
      <li parentName="ul">{`Antes de cada encuentro se enviará el material complementario vía correo electrónico.`}</li>
      <li parentName="ul">{`Cada sesión se acompaña con ejercicios para realizar en casa de forma autónoma.`}</li>
      <li parentName="ul">{`Los contenidos dependerán del progreso y las necesidades de cada persona.`}</li>
      <li parentName="ul">{`Es posible elegir un horario fijo o flexible de acuerdo a la disponibilidad de cada estudiante.`}</li>
    </ul>
    <h3>{`Horarios`}</h3>
    <p>{`El horario disponible para realizar las sesiones es de `}<strong parentName="p">{`lunes a viernes`}</strong>{` de `}<strong parentName="p">{`09:00`}</strong>{` a `}<strong parentName="p">{`20:00`}</strong>{` horas (UTC-3).`}</p>
    <h3>{`¿Qué dicen nuestros alumnos?`}</h3>
    <p>{`Estos son los comentarios que hemos recibido en el portal de `}<a parentName="p" {...{
        "href": "https://www.tusclasesparticulares.cl"
      }}>{`Tus Clases Particulares`}</a>{`.`}</p>
    <Opiniones mdxType="Opiniones" />
    <h3>{`Acerca de`}</h3>
    <p><strong parentName="p">{`L'atelier de français`}</strong>{` es un proyecto de `}<a parentName="p" {...{
        "href": "https://www.tusclasesparticulares.cl/profesores/marcela-fernanda-valdes-rojas.htm"
      }}>{`Marcela Valdés`}</a>{`, arquitecta y entusiasta del idioma francés.`}</p>
    <p>{`Puedes encontrarnos en `}<a parentName="p" {...{
        "href": "https://www.instagram.com/latelierdefrancais/?hl=es-la"
      }}>{`Instagram`}</a>{`, `}<a parentName="p" {...{
        "href": "https://web.facebook.com/latelierdefrancais.cl"
      }}>{`Facebook`}</a>{`, escríbirnos a `}<a parentName="p" {...{
        "href": "mailto:bonjour@latelierdefrancais.cl"
      }}>{`bonjour@latelierdefrancais.cl`}</a>{` o vía `}<a parentName="p" {...{
        "href": "https://wa.me/56982873103"
      }}>{`WhatsApp`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      